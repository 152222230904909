<div class="modal-lg">
    <div class="modal-content">
        <div class="modal-header pb-1"
        style='border: none;'>
        <h4>
          <p class="font-weight-bold mb-0">{{title}}</p>
          <span class="subtext-text-color">{{subtitle}}</span>

        </h4>

        
           
            <button type="button" class="close pull-right" aria-label="Close" (click)="bsModalRef.hide()">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <img src="assets/icons/line.png" style="max-width: 100%;" />
        <div class="modal-body pt-1">
            <div *ngIf="list.length">
                <p *ngFor="let item of list">{{item}}</p>
            </div>
        </div>
        <div class="modal-footer" style='border: none;'>
            <button type="button" class="btn btn-dark" (click)="bsModalRef.hide()">
                Close
              </button>
        </div>
    </div>
</div>