import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { TemplateRef } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms'
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { WebServiceService } from '../../services/web-service.service';
import { ToastrService } from 'ngx-toastr';
@Component({
  selector: 'app-quote',
  templateUrl: './quote.component.html',
  styleUrls: ['./quote.component.css']
})
export class QuoteComponent implements OnInit {
  @Output() notifyParent: EventEmitter<any> = new EventEmitter();
  getQuoteForm: FormGroup;
  responseMsg: string = "";
  isWebRequest: boolean = false;
  public data: any = {};
  modalReference: any;
  submitted: boolean;
  selectedCode: any;
  counryCode: any;
  formSumitAttempt: boolean;
  img1='assets/icons/Submit.png'
  img2='assets/icons/Submit-disabled.png'
  constructor(private _formBuilder: FormBuilder,
    public bsModalRef: BsModalRef,
    private _webService: WebServiceService,
    private toastr: ToastrService) {
    this.getQuoteForm = this._formBuilder.group({
      firstName: [null, [Validators.required]],
      emailId: [null, [Validators.required, Validators.email]],
      contactNumber: [null, [Validators.required]],
      message: [null, [Validators.required]],
      countryCode: [null, [Validators.required]],

    });
  }
  ngOnInit(): void {
    this.counryCode=this._webService.countryCode;
    this.selectedCode = 'US-(+1)'
  }

  get f() {
    return this.getQuoteForm.controls;
  }
  isFieldValid(field: string) {
    return (
      (!this.getQuoteForm.get(field).valid && this.getQuoteForm.get(field).touched) ||
      (!this.getQuoteForm.get(field).valid &&
        this.getQuoteForm.get(field).untouched && this.formSumitAttempt)
    );
  }

  addQuote() {
    this.formSumitAttempt = true;
    if (this.getQuoteForm.valid) {
      if(this.getQuoteForm.invalid){
        return;
      }
      this.isWebRequest = true;
      this.responseMsg = "Please wait...";
      let final
    if(this.selectedCode){
      const country = this.selectedCode.split("-");
      const code = country[0]
       final = (this.counryCode.find(o => o.code == code))

    }
      let data = {
        name: this.getQuoteForm.value.firstName,
        email: this.getQuoteForm.value.emailId,
        mobile: this.getQuoteForm.value.contactNumber,
        message: this.getQuoteForm.value.message,
        code: final.dial_code?final.dial_code:null
      }
      this._webService.addQuote(data).subscribe(
        (res: any) => {
          this.getQuoteForm.reset();
          this.submitted = false;
          if (res.success == true) {
            this.getQuoteForm.clearValidators();
            this.toastr.success("Thank you for your request. We will get back to you soon...");
            this.bsModalRef.hide();
  
          }
        },
        (err: any) => {
          this.toastr.error(err.message);
          this.bsModalRef.hide();
        }
        );
    }
  }
}
