import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './components/home/home.component';

const routes: Routes = [
  // { path: '', component: HomeComponent },
  {
    path: '',
    loadChildren: () =>
      import('./shared/shared.module').then((m) => m.SharedModule),
  },
 
];

@NgModule({
  imports: [RouterModule.forRoot(routes,
     {
    useHash: true,
    anchorScrolling: 'enabled', })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
