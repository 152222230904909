import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CookieService } from 'ngx-cookie-service';
import { AuthService } from '../../services/auth.service';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css'],
})
export class LoginComponent implements OnInit {
  clickEventsubscription: Subscription;
  loginForm: FormGroup;
  submitted = false;
  msg: string;

  constructor(
    private formBuilder: FormBuilder,
    private authService: AuthService,
    private cookie: CookieService,
    private router: Router
  ) { }

  ngOnInit() {
    this.loginForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.required]],
    });
  }
  get f() {
    return this.loginForm.controls;
  }

  onSubmit() {
    this.submitted = true;
    if (this.loginForm.invalid) {
      return;
    }
    this.authService.login(this.loginForm.value).subscribe(
      (res: any) => {
        if (res.success === true) {
          localStorage.setItem('token', res.token);

          if (localStorage.getItem('token')) {
            this.router.navigate(['admin-dashboard']);
          }
        }
      },
      (err: any) => {
        this.msg = 'Invalid Credentials';
      }
    );
  }

  passHideShow() {
    const loginPass = document.querySelector('#login-pass');
    const loginEye = document.querySelector('#login-eye');
    if (loginPass.getAttribute('type') === 'password') {
      loginPass.setAttribute('type', 'text');
      loginEye.classList.replace('fa-eye-slash', 'fa-eye');
    } else {
      loginPass.setAttribute('type', 'password');
      loginEye.classList.replace('fa-eye', 'fa-eye-slash');
    }
  }
}
