import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { FooterComponent } from '../footer/footer.component';
import { QuoteComponent } from '../quote/quote.component';


@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.css'],
})
export class NavbarComponent implements OnInit {
  btnLogin: boolean;
  btnLogout: boolean;
  modalRef: BsModalRef;
  innerwidth:any;
  activeTab:string="/";
  collapsed = true;
   toggleCollapsed(): void { this.collapsed = !this.collapsed; }
  constructor(
    private router: Router,private modalService: BsModalService
  ) {
    try {
      router.events.subscribe((val) => {
        // see also 
        if (window.location.href.toString().includes('')) {
          this.activeTab = 'home'
        } 
        if (window.location.href.toString().includes('service')) {
          this.activeTab = 'service'
        }
        if( this.innerwidth > 730)
        if (window.location.href.toString().includes('our-clients')||window.location.href.toString().includes('our-team')||
        window.location.href.toString().includes('career')||window.location.href.toString().includes('about-us')){
          this.activeTab = 'work'

        }
        if( this.innerwidth < 730){
        if (window.location.href.toString().includes('contact-us')) {
          this.activeTab = 'contact'
        }
         if (window.location.href.toString().includes('about-us')) {
          this.activeTab = 'about'
        }
      
      if (window.location.href.toString().includes('our-clients')) {
       this.activeTab = 'client'
     }
     if (window.location.href.toString().includes('our-team')) {
      this.activeTab = 'team'
    }
       
    if (window.location.href.toString().includes('career')) {
      this.activeTab = 'career'
    }}
      });

    } catch (e) {

    }
  }

  ngOnInit() {
    this.innerwidth=window.innerWidth;
  }
  onResize($event) {
    this.innerwidth = window.innerWidth;
  }
  u()
{ var toggleButton = document.querySelector('.toggle-menu');
  var navBar = document.querySelector('.nav-bar');
  toggleButton.addEventListener('click', function () {
      navBar.classList.toggle('toggle');
  });}

  openModalWithComponent() {
    const initialState:any = {
      list: [
        'Open a modal with component',
        'Pass your data',
        'Do something else',
        '...'
      ],
      title: 'Modal with component'
    };
    this.modalRef = this.modalService.show(QuoteComponent, {initialState});
    this.modalRef.content.closeBtnName = 'Close';
  }

}