import { Component, OnInit } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { WebServiceService } from '../../../services/web-service.service';
@Component({
  selector: 'app-email-modal',
  templateUrl: './email-modal.component.html',
  styleUrls: ['./email-modal.component.css']
})
export class EmailModalComponent implements OnInit {
  title: string;
  closeBtnName: string;
  list: any[] = [];
  emailForm: FormGroup;
  submitted: boolean = false;
  img1='assets/icons/Submit.png'
  img2='assets/icons/Submit-disabled.png'
  formSumitAttempt: boolean;
  constructor(
    public bsModalRef: BsModalRef,
    private _formBuilder: FormBuilder,
    private  toastr:ToastrService,
    private _webService: WebServiceService,
    ) {}
 
  ngOnInit() {
    this.emailForm = this._formBuilder.group({
      client_name: [null, [Validators.required]],
      client_email: [null, [Validators.required, Validators.email]]
    });
    
    // document.getElementsByClassName('modal-content')[0].className +=
    //   ' custom-border-rounded';
   }

   get f() {
		return this.emailForm.controls;
	}
  isFieldValid(field: string) {
    return (
      (!this.emailForm.get(field).valid && this.emailForm.get(field).touched) ||
      (!this.emailForm.get(field).valid &&
        this.emailForm.get(field).untouched && this.formSumitAttempt)
    );
  }
   emailSubmit(){
    this.formSumitAttempt = true;
    if (this.emailForm.valid) {

      this.submitted =true;
      if(this.emailForm.invalid){
        return;
      }else{
        const formData: any = new FormData();
        formData.append('service_name', this.title);
        const Data = {
          ...this.emailForm.value,
          service_name: this.title
        };
        // formData.append('Data', JSON.stringify(Data));
        this._webService.getTemplate(Data).subscribe(
          (res: any) => {
            this.submitted = false;
            if (res.success == true) {
              this.emailForm.clearValidators();
              this.toastr.success('We sent template on '+this.f.client_email.value);
              this.bsModalRef.hide();  
            }
            this.emailForm.reset();

          },
          (err: any) => {
            this.toastr.error(err.message);
            this.bsModalRef.hide();
          }
        );
      }
    }

   }
  
}
