import { Injectable, EventEmitter } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Subscription } from 'rxjs/internal/Subscription';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  toggleBtn = new EventEmitter();
  subsVar: Subscription;
  constructor(private http: HttpClient) {}
  toggleBtnFun() {
    this.toggleBtn.emit();
  }

  login(data) {
    return this.http.post(environment.url + 'auth/login', data);
  }

  logout() {
    return this.http.post(environment.url + 'auth/logout', null);
  }
}
