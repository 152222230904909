<div class="container my-5 content">
  <div class="row justify-content-center">
    <div class="col-md-5">
      <div class="card text-center">
       
        <div class="card-body">
          <h3 class="m-0 font-weight-bold text-center" style="color:black;">Login</h3>
          <form [formGroup]="loginForm" (ngSubmit)="onSubmit()">
            <div class="form-group">
              <strong class="text-danger">{{msg}}</strong>
            </div>
            <div class="form-group">
              <input type="text" formControlName="email" class="form-control form-control-sm px-3"
                [ngClass]="{ 'is-invalid': submitted && f.email.errors }" placeholder="Email" />
              <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
                <div *ngIf="f.email.errors.required">Email is required</div>
                <div *ngIf="f.email.errors.email">Email is invalid</div>
              </div>
            </div>
            <div class="form-group position-relative">
              <i class="fas fa-eye-slash position-absolute btn border-0 eye-position" id="login-eye"
                (click)="passHideShow()"></i>
              <input type="password" formControlName="password" class="form-control form-control-sm px-3"
                id="login-pass" [ngClass]="{ 'is-invalid': submitted && f.password.errors }" placeholder="Password" />
              <div *ngIf="submitted && f.password.errors" class="invalid-feedback">
                <div *ngIf="f.password.errors.required">Password is required</div>
                <div *ngIf="f.password.errors.minlength">Password must be at least 6 letters</div>
              </div>
            </div>
            <div class="form-group">
              <button type="submit" class="btn">Login
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
