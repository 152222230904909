<nav (window:resize)="onResize($event)" class="navbar navbar-expand-lg shadow-lg mb-5 navbar-light bg-light">

    <div class="container">
        <!-- Brand -->
        <a class="navbar-brand" href="index.html">
            <img alt="Image placeholder" src="assets/icons/Logo.png" style="height: 4.75rem;" id="navbar-logo">
        </a>
        <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation" (click)="toggleCollapsed()"> <span class="navbar-toggler-icon"></span> </button>
        <!-- Collapse -->
        

    </div>

</nav>