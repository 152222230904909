<div class="modal-header d-block" (window:resize)="onResize($event)" style="background: -webkit-linear-gradient(left, #1e5799 0%,#f6481e 0%,#f87f3c 100%);">
    <button type="button" class="close pull-right buttons-color" aria-label="Close" (click)="bsModalRef.hide()">
        <span aria-hidden="true">&times;</span>
    </button>
    <h3 class="font-weight-bold text-white text-left">Join the ArightCo Team!</h3>

    <p class="text-white text-left" style="font-size: 16px;">Please fill out the form below to take the first <br>step to join our growing team</p>
</div>
<div class="modal-body">

    <div class="container">
        <form [formGroup]="jobApplyForm" role="form" autocomplete="off">
            <div class="row">
                <div class="col-sm-6">
                    <div class="form-group">
                        <input type="text" class="form-control" formControlName="first_name" placeholder="Enter First Name *">
                        <!-- *ngIf="f.first_name.invalid && (f.first_name.dirty || f.first_name.touched)" -->
                        <ul class="parsley-errors-list filled text-red" *ngIf="isFieldValid('first_name')">
                            <li class="parsley-required" *ngIf="f.first_name.errors.required">First Name is required.
                            </li>
                        </ul>
                    </div>
                </div>

                <div class="col-sm-6">
                    <div class="form-group">
                        <input class="form-control" type="text" formControlName="last_name" placeholder="Enter Last Name *">
                        <!-- *ngIf="f.last_name.invalid && (f.last_name.dirty || f.last_name.touched)" -->
                        <ul class="parsley-errors-list filled text-red" *ngIf="isFieldValid('last_name')">
                            <li class="parsley-required" *ngIf="f.last_name.errors.required">Last Name is required.</li>
                        </ul>
                    </div>
                </div>
            </div>



            <div class="row">
                <div class="col-sm-12">
                    <div class="form-group">
                        <input class="form-control" type="text" formControlName="email" placeholder="Email Id *">
                        <!-- *ngIf="f.email.invalid && (f.email.dirty || f.email.touched)" -->
                        <ul class="parsley-errors-list filled text-red" *ngIf="isFieldValid('email')">
                            <li class="parsley-required" *ngIf="f.email.errors.required">Email id is required.</li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-5 " *ngIf="innerwidth > 730">
                    <ng-select [(ngModel)]="selectedCode" class="custom1" placeholder="Country Code*" formControlName="countryCode">
                        <ng-option *ngFor="let code of counryCode" [value]="code.dial_code">{{code.code}}-({{code.dial_code}})</ng-option>
                    </ng-select>
                    <!-- *ngIf="f.countryCode.invalid && (f.countryCode.dirty || f.countryCode.touched)" -->
                    <ul class="parsley-errors-list filled text-red" *ngIf="isFieldValid('countryCode')">
                        <li class="parsley-required" *ngIf="f.countryCode.errors.required">
                            Country code is required.</li>
                    </ul>
                </div>
                <div class="col-sm-5 pb-3" *ngIf="innerwidth < 730">
                    <ng-select [(ngModel)]="selectedCode" class="custom1"placeholder="Country Code*" formControlName="countryCode">
                        <ng-option *ngFor="let code of counryCode" [value]="code.dial_code">{{code.code}}-({{code.dial_code}})</ng-option>
                    </ng-select>
                    <!-- *ngIf="f.countryCode.invalid && (f.countryCode.dirty || f.countryCode.touched)" -->
                    <ul class="parsley-errors-list filled text-red" *ngIf="isFieldValid('countryCode')">
                        <li class="parsley-required" *ngIf="f.countryCode.errors.required">
                            Country code is required.</li>
                    </ul>
                </div>
              
                <div class="col-sm-7">

                    <input type="number" class="form-control" formControlName="mobile" placeholder="Mobile No *">
                    <!-- *ngIf="f.mobile.invalid && (f.mobile.dirty || f.mobile.touched)" -->
                    <ul class="parsley-errors-list filled text-red" *ngIf="isFieldValid('mobile')">
                        <li class="parsley-required" *ngIf="f.mobile.errors.required">Mobile Number is required.
                        </li>
                    </ul>
                </div>
            </div> <br>
            <div class="row">
                <div class="col-sm-12">
                    <label for="image">
                    </label>
                    <div>
                        <div>
                            <button class="btn btn-sm badge-pill" style="background-color: #473f3d;color: #f2f2f2;font-weight: 600;" (click)="_upload()">
                                Upload Resume
                            </button>
                            <span *ngIf="fileName"> {{fileName}}</span>
                            <input type="file" style="display: none;" id="file_upload_id" (change)="onFileChange($event)" accept="application/pdf" />

                        </div>
                    </div>

                </div>
            </div>

        </form>
    </div>
</div>
<div class="modal-footer">
    <img src="{{ this.jobApplyForm?.valid ?img1 : img2 }}"  class="clickable" (click)="addJobApply()" type="button" alt="instagram" style="width:30%;">
</div>