import { Component, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { BsModalRef } from 'ngx-bootstrap/modal';
@Component({
  selector: 'app-delete',
  templateUrl: './delete.component.html',
  styleUrls: ['./delete.component.css']
})
export class DeleteComponent implements OnInit {

  public onClose: Subject<boolean>;

  constructor(public bsModalRef: BsModalRef) { }

  public ngOnInit(): void {
      this.onClose = new Subject();
  }

  public onConfirm(): void {
      this.onClose.next(true);
      this.bsModalRef.hide();
  }

  public onCancel(): void {
      this.onClose.next(false);
      this.bsModalRef.hide();
  }

}
