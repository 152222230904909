import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { TemplateRef } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { FormGroup, FormBuilder, Validators } from '@angular/forms'
import { WebServiceService } from '../../../services/web-service.service';
import { ToastrService } from 'ngx-toastr';
@Component({
  selector: 'app-job-apply',
  templateUrl: './job-apply.component.html',
  styleUrls: ['./job-apply.component.css']
})
export class JobApplyComponent implements OnInit {
  @Output() notifyParent: EventEmitter<any> = new EventEmitter();
  title: any;
  jobApplyForm: FormGroup;
  responseMsg: string = "";
  isWebRequest: boolean = false;
  public data: any = {};
  modalReference: any;
  filesToUpload: File[];
  fileLength = 0;
  submitted: boolean;
  selectedCode: any;
  counryCode: any;
  fileName: any;
  formSumitAttempt: boolean;
  img1='assets/icons/Submit.png'
  img2='assets/icons/Submit-disabled.png'
  innerwidth: number;
  constructor(private _formBuilder: FormBuilder,
    private _webService: WebServiceService,
    private toastr: ToastrService,
    public bsModalRef: BsModalRef) {
      console.log(this.title)
    this.jobApplyForm = this._formBuilder.group({
      first_name: [null, [Validators.required]],
      last_name: [null, [Validators.required]],
      email: [null, [Validators.required, Validators.email]],
      mobile: [null, [Validators.required]],
      countryCode: [null, [Validators.required]],
    });
  }

  ngOnInit(): void {
    this.innerwidth=window.innerWidth;
    this.counryCode=this._webService.countryCode;
    this.selectedCode = 'US-(+1)'
    // document.getElementsByClassName('modal-content')[0].className +=
    //   ' custom-border-rounded';
  }
  onResize($event) {
    this.innerwidth = window.innerWidth;
  }
  get f() {
    return this.jobApplyForm.controls;
  }
  isFieldValid(field: string) {
    return (
      (!this.jobApplyForm.get(field).valid && this.jobApplyForm.get(field).touched) ||
      (!this.jobApplyForm.get(field).valid &&
        this.jobApplyForm.get(field).untouched && this.formSumitAttempt)
    );
  }
  onFileChange(fileInput: any) {
    this.filesToUpload = fileInput.target.files[0];
    this.fileLength = this.filesToUpload['name'].length;
    this.fileName = this.filesToUpload['name'];
  }

  _upload() {
      document.getElementById('file_upload_id').click();
    }

  addJobApply() {
    console.log(this.title)
    this.formSumitAttempt = true;
    if (this.jobApplyForm.valid) {

      const val = Math.floor(Math.random()*90000) + 10000;
      this.isWebRequest = true;
      const formData: any = new FormData();
      let final = null
      if(this.selectedCode){
        const country = this.selectedCode.split("-");
        const code = country[0]
         final = (this.counryCode.find(o => o.code == code))
  
      }
      if(final.dial_code){
        this.f.countryCode.setValue(final.dial_code);
      }else{
        this.f.countryCode.setValue(final);
      }
      
      const Data = {
        ...this.jobApplyForm.value,
        resume: val+this.filesToUpload['name'],
        job_name:this.title.job_desc,
        job_id:this.title.id,
      };
      formData.append('resume', this.filesToUpload, val+this.filesToUpload['name']);
      formData.append('Data', JSON.stringify(Data));
  
      this._webService.applyJob(formData).subscribe(
        (res: any) => {
          this.jobApplyForm.reset();
          this.submitted = false;
          if (res.success == true) {
            this.jobApplyForm.clearValidators();
            this.toastr.success('Job Application submitted');
            this.bsModalRef.hide();
  
          }
        },
        (err: any) => {
          this.toastr.error(err.message);
          this.bsModalRef.hide();
        }
      );
    
    }
  }
}
