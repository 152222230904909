import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { AuthService } from './services/auth.service';
import { CookieService } from 'ngx-cookie-service';
import { AuthInterceptor } from './auth.interceptor';
import { FooterComponent } from './components/footer/footer.component';
import { NavbarComponent } from './components/navbar/navbar.component';
import { ModalModule } from 'ngx-bootstrap/modal';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { ToastrModule } from 'ngx-toastr';
import { CommonModule } from '@angular/common';
import { QuoteComponent } from './components/quote/quote.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { environment } from 'src/environments/environment';
import { ServiceWorkerModule } from '@angular/service-worker';
import { NgSelectModule } from '@ng-select/ng-select';
import { ReadMoreTeamComponent } from './components/modals/read-more-team/read-more-team.component';
import { LoginComponent } from './components/login/login.component';
import { DeleteComponent } from './components/modals/delete/delete.component';
import { EmailModalComponent } from './components/modals/email-modal/email-modal.component';
import { JobApplyComponent } from './components/modals/job-apply/job-apply.component';
import { AdminAlbumsComponent } from './components/adminPanel/admin-albums/admin-albums.component';
import { AdminGallaryComponent } from './components/adminPanel/admin-gallary/admin-gallary.component';

import { NgxPaginationModule } from 'ngx-pagination';
@NgModule({
  declarations: [
    AppComponent,
    NavbarComponent,
    FooterComponent,
    QuoteComponent,
    ReadMoreTeamComponent,
    EmailModalComponent,
    JobApplyComponent,
    LoginComponent,
    DeleteComponent,
    AdminAlbumsComponent,
    AdminGallaryComponent,
  ],
  imports: [

    BrowserModule,
    ReactiveFormsModule, BrowserAnimationsModule,
    AppRoutingModule,
    NgxPaginationModule,
    HttpClientModule,
    CarouselModule,
    ModalModule.forRoot(),
    BsDropdownModule.forRoot(),
    NgSelectModule,
    // bootstrapCarousel.forRoot(),
    // CollapseModule.forRoot(),
    ToastrModule.forRoot(),
    // CKEditorModule,
    // PaginationModule.forRoot(),
    
    // TooltipModule.forRoot(),
    // SortableModule.forRoot(),
    ServiceWorkerModule.register('./ngsw-worker.js', { enabled: environment.production }),
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
    CookieService,
    AuthService,

  ],
  bootstrap: [AppComponent],
  entryComponents: [
    QuoteComponent,
    ReadMoreTeamComponent,
    EmailModalComponent,
    JobApplyComponent,
    LoginComponent,
    DeleteComponent,
  ],
})
export class AppModule { }
