<div class="modal-header d-block" style="background: -webkit-linear-gradient(left, #1e5799 0%,#f6481e 0%,#f87f3c 100%);">
    <button type="button" class="close pull-right buttons-color" aria-label="Close" (click)="bsModalRef.hide()">
        <span aria-hidden="true">&times;</span>
    </button>
    <h3 class="font-weight-bold text-white">Get a Quote!</h3>

    <p class="text-white m-0" style="font-size: 20px;">
        Please fill out the form to get a quote</p>
</div>

<div class="modal-body models-body-height pt-4 px-4">
    <form [formGroup]="getQuoteForm" (ngSubmit)="addQuote()" role="form">
        <div class="row ">
            <div class="col-sm-12">
                <div>
                    <div class="content mt-4 mx-auto">
                        <input type="text" class="form-control" formControlName="firstName" placeholder="Enter Name *">
                        <!-- *ngIf="f.firstName.invalid && (f.firstName.dirty || f.firstName.touched)" -->
                        <ul class="py-0 parsley-errors-list filled text-red" *ngIf="isFieldValid('firstName')">
                            <li class="parsley-required" *ngIf="f.firstName.errors.required">Name is required.</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
        <div class="row ">
            <div class="col-sm-12">
                <div>

                    <div class="content mt-4 mx-auto">
                        <input type="email" class="form-control" formControlName="emailId" placeholder="Email *">
                        <!-- *ngIf="f.emailId.invalid && (f.emailId.dirty || f.emailId.touched)" -->
                        <ul class="py-0 parsley-errors-list filled text-red" *ngIf="isFieldValid('emailId')">
                            <li class="parsley-required" *ngIf="f.emailId.errors.required">Email Id is required.</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>

        <div class="row ">
            <div class="col-sm-6"><br>
                <ng-select [(ngModel)]="selectedCode" class="custom1" placeholder="Country Code*" formControlName="countryCode">
                    <ng-option *ngFor="let code of counryCode" [value]="code.dial_code">
                        {{code.code}}-({{code.dial_code}})</ng-option>
                </ng-select>
                <!-- *ngIf="f.countryCode.invalid && (f.countryCode.dirty || f.countryCode.touched)" -->
                <ul class="py-0 parsley-errors-list filled text-red" *ngIf="isFieldValid('countryCode')">
                    <li class="parsley-required" *ngIf="f.countryCode.errors.required">
                        Country code is required.</li>
                </ul>
            </div>
            <div class="col-sm-6">
                <div>

                    <div class="content mt-4 mx-auto">
                        <input type="number" class="form-control" formControlName="contactNumber" placeholder="Mobile Number *">
                        <!-- *ngIf="f.contactNumber.invalid && (f.contactNumber.dirty || f.contactNumber.touched)" -->
                        <ul class="py-0 parsley-errors-list filled text-red" *ngIf="isFieldValid('contactNumber')">
                            <li class="parsley-required" *ngIf="f.contactNumber.errors.required">Mobile No is required.
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-sm-12">
                <div>

                    <div class="content mt-4 mx-auto">
                        <div class="file-upload-wrapper">
                            <textarea class="form-control" rows="5" formControlName="message" placeholder="Message *"></textarea>
                            <!-- *ngIf="f.message.invalid && (f.message.dirty || f.message.touched)" -->
                            <ul class="py-0 parsley-errors-list filled text-red" *ngIf="isFieldValid('message')">
                                <li class="parsley-required" *ngIf="f.message.errors.required">Text message is required.
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>


    </form>
</div>
<div class="modal-footer">
    <img src="{{ this.getQuoteForm?.valid ?img1 : img2 }}" type="file" alt="instagram" style="width:30%;cursor: pointer;" (click)="addQuote()">

</div>