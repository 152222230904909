<form [formGroup]="emailForm" (ngSubmit)="emailSubmit()" role="form">
    <div class="modal-header d-block" style="background: -webkit-linear-gradient(left, #1e5799 0%,#f6481e 0%,#f87f3c 100%);">
        <button type="button" class="close pull-right buttons-color" aria-label="Close" (click)="bsModalRef.hide()">
            <span aria-hidden="true">&times;</span>
        </button>
        <h3 class=" text-white text-left">Enter Your Email Address</h3>
    
        
    </div>
    <div class="modal-body">

        <div class="row placeholder-height">
            <div class="col-sm-12 my-3">
                <div class="container">
                    <div class="form-group">
                        <input type="text" formControlName="client_name" class="form-control" placeholder="Enter name">
                        <!-- *ngIf="f.client_name.invalid && (f.client_name.dirty || f.client_name.touched)" -->
                        <ul class="parsley-errors-list filled text-red" *ngIf="isFieldValid('client_name')">
                            <li class="parsley-required" *ngIf="f.client_name.errors.required">
                                Client name is required.</li>
                        </ul>
                    </div>
                    <div class="form-group">
                        <input type="email" formControlName="client_email" class="form-control" placeholder="Enter email">
                        <!-- *ngIf="f.client_email.invalid && (f.client_email.dirty || f.client_email.touched)" -->
                        <ul class="parsley-errors-list filled text-red" *ngIf="isFieldValid('client_name')">
                            <li class="parsley-required" *ngIf="f.client_email.errors.required">
                                Client email is required.</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>



    </div>
    <div class="modal-footer">
        <img src="{{ this.emailForm?.valid ?img1 : img2 }}" class="clickable" (click)="emailSubmit()" style="cursor: pointer;" type="file" alt="instagram" style="width:30%;">

    </div>
</form>