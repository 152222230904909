import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';


@Component({
  selector: 'app-read-more-team',
  templateUrl: './read-more-team.component.html',
  styleUrls: ['./read-more-team.component.css'],
})
export class ReadMoreTeamComponent implements OnInit {
  title: string;
  subtitle: string;
  closeBtnName: string;
  list: any[] = [];

  constructor(public bsModalRef: BsModalRef) { }

  ngOnInit() 
  {
    // this.list.push('PROFIT!!!');
  }
}